<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">تعديل حالة الطلب</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <form @submit.prevent="UpdateOrder">
                  <div class="form-group">
                    <label for="categories">
                      الحالات
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selected"
                      :value="selected"
                      id="statues"
                      :options="Statues"
                      :searchable="true"
                      selectedLabel=" العنصر المحدد"
                      deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                      selectLabel="اضغط لإختيار هذا العنصر"
                      :close-on-select="true"
                      placeholder="اختر"
                      label="name"
                      track-by="name"
                      required
                    >
                      <span slot="noOptions">اللائحة فارغة</span>
                      <span slot="noResult">لا توجد نتيجة</span>
                    </multiselect>
                  </div>

                  <button type="submit" class="btn btn-success">
                    تحديث الحالة
                  </button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "EditSupplier",
  data() {
    return {
      order: [],
      Statues: [],
      selected: "",
    };
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_URL}orders/${this.$route.params.id}`)
      .then((response) => {
        this.order = response.data.data;
        this.selected = response.data.data.status;
      });
    let statuses = [];
    this.$http.get(`${process.env.VUE_APP_URL}orders-statues`).then((response) => {
      $.each(response.data.data, function (key, value) {
        statuses.push({ id: value.id, name: value.name });
      });
      this.Statues = statuses;
    });
  },
  methods: {
    UpdateOrder() {
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("status_id", this.selected.id);
  
      this.$http
        .post(
          `${process.env.VUE_APP_URL}orders/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            this.$router.push({ path: "/orders" });
          } else {
            swal({ title: response.data.message, buttons: "تم" });
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>